import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {NotFoundComponent} from './not-found/not-found.component';
import {HeaderComponent} from './base/header/header.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {InterceptorService} from "./interceptor/interceptor.service";
import {ErrorInterceptor} from "./interceptor/error.interceptor";
import {DisplayDataModule} from "./shared/display-data/display-data.module";
import {DirectivesModule} from "./shared/directives/directives.module";
import {AppInitService} from "./services/init/app-init.service";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {CurrencyPipe} from "@angular/common";
import {GettingStartedComponent} from './getting-started/getting-started.component';
import {ItemModalsModule} from "./shared/modals/item-modals/item-modals.module";
import {CommonModalsModule} from "./shared/modals/common-modals/common-modals.module";
import {DownloadFileComponent} from './download-file/download-file.component';
import {ConfirmationService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {NgbDropdownModule, NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {AccordionModule} from "primeng/accordion";
import {FormsModule} from "@angular/forms";
import {SharedComponentsModule} from "./shared/shared-components/shared-components.module";
import {ImageSizePipe} from "./shared/pipes/imageSize/image-size.pipe";

export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    console.error(error);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HeaderComponent,
    GettingStartedComponent,
    DownloadFileComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DisplayDataModule,
    DirectivesModule,
    ScrollingModule,
    HttpClientModule,
    ItemModalsModule,
    CommonModalsModule,
    NgbDropdownModule,
    NgbPopoverModule,
    AccordionModule,
    FormsModule,
    NgbTooltipModule,
    SharedComponentsModule,
    ConfirmDialogModule
  ],
  providers: [
    AppInitService,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: TrimRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => () => appInitService.init(),
      multi: true,
      deps: [AppInitService]
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    CurrencyPipe,
    ImageSizePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
